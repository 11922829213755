
import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react'; // Replace 'your-editor-library' with the actual library you are using

const Contact = () => {
    const API = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Basic validation
        if (!formData.name || !formData.email || !formData.subject || !formData.message) {
            setError('All fields are required.');
            return;
        }
    
        try {
            const response = await fetch(`${API}/contact/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
    
            if (response.ok) {
                setSubmitted(true); // Set submitted to true to hide the form
                setFormData({ name: '', email: '', subject: '', message: '' });
                setError('');
            } else {
                // Try to parse the response as JSON
                try {
                    const data = await response.json();
                    setError(data.detail || 'Failed to send your message. Please try again later.');
                } catch (jsonError) {
                    // If parsing fails, it means the response is not JSON
                    setError('An unexpected error occurred. Please try again later.');
                }
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }
    }


    return (
        <Container className="p-4">
            <h2 className="mb-4">Contact Us</h2>
            {submitted && <Alert color="success">Your message has been sent successfully!</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
            <Row>
            {!submitted &&
                <Col md="6">
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Your name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Your email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="subject">Subject</Label>
                            <Input
                                type="text"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Message</Label>
                            <Editor tinymceScriptSrc={`/tinymce/js/tinymce/tinymce.min.js`}
                                value={formData.message}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={(content) => setFormData({ ...formData, message: content })}
                            ></Editor>
                        </FormGroup>
                        <Button color="primary" type="submit">Submit</Button>
                    </Form>
                </Col>
                }
                <Col md="6">
                    <h4>Our Location</h4>
                    <p>Khouribga</p>
                    <p>24000</p>
                    <p>Morocco</p>

                </Col>
            </Row>
        </Container>
    );
};

export default Contact;