import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './template/Layout';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import Register from './components/auth/Register';
import Profile from './components/Profile';
import PostDetail from './components/PostDetail';
import PostList from './components/PostList';
import ClassList from './components/ClassList';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from './redux/actions';
import About from './components/About';
import Contact from './components/Contact';
// import Maintenance from './components/Maintenance';
import DictionarySearch from './components/DictionarySearch';

import ReactGa from 'react-ga4';
import Dashboard from './components/Dashboard';

ReactGa.initialize('G-NJGSQ5V5EJ');





function App() {

  const dispatch = useDispatch();
  // const location = useLocation();

// Site title
  useEffect(() => {
    document.title = "HelB Portal"
  })
  // Track your authentication
  useEffect(() => {
      if (localStorage.getItem('auth_token')) {
          dispatch(fetchUser());
      }
  }, [dispatch]);

  // Google Analytics

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  console.log(window.location.hostname, window.location.port, window.location.pathname)



  return (
    <Layout>
      <Routes>
        <Route path="/" element={<PostList />} />
        {/* <Route path="/" element={<Maintenance />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/me" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dictionary" element={<DictionarySearch />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/post/:postId" element={<PostDetail />} />
        <Route path="/classes" element={<ClassList />} />
        <Route path="/class/:classId/posts" element={<PostList />} />
        <Route path="/class/:classId/skill/:skillId/posts" element={<PostList />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

function NotFound() {
  return <h1>404 - Page Not Found</h1>;
}

export default App;