// frontend/src/components/SkillList.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row } from 'reactstrap';

const SkillList = () => {
    const { classId } = useParams(); // Extract classId from URL params
    const [skills, setSkills] = useState([]); // State to store skills
    // Remove the unused variable
    const [, setSelectedSkillId] = useState(null); // Track selected skill
    const navigate = useNavigate(); // Correct usage of useNavigate
    const API = process.env.REACT_APP_API_URL; // API URL from environment variables

    // Fetch skills when the component mounts or when classId or skills change
    useEffect(() => {
        const fetchSkills = async () => {
            try {
                const response = await fetch(`${API}/class/${classId}/`);
                if (!response.ok) {
                    throw new Error('Error fetching skills');
                }
                const data = await response.json();
                setSkills(data.skills || []); // Update the skills state with the fetched data
            } catch (error) {
                console.error('Error fetching skills:', error);
            }
        };
    
        fetchSkills();
    }, [classId, API]);

    // Handle skill selection and navigate to the posts page for the selected skill
    const handleSkillClick = (skillId) => {
        setSelectedSkillId(skillId); // Update selected skill state
        navigate(`/class/${classId}/skill/${skillId}/posts`); // Navigate to the posts page
    };

    return (
        <Row>
            {skills.length > 0 ? (
                <div className="container-fluid mb-3">
                    <h3>Skills</h3>
                    {skills.map(skill => (
                        <button
                            key={skill.id}
                            type="button"
                            className="btn btn-outline-success m-2 btn-lg" // Add margin to space out buttons
                            onClick={() => handleSkillClick(skill.id)} // Corrected onClick handler
                        >
                            {skill.name}
                        </button>
                    ))}
                </div>
            ) : (
                <p>No skills available.</p> // Message if no skills are returned
            )}
        </Row>
    );
};

export default SkillList;
