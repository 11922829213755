import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import FooterInfo from './FooterInfo';
// import Header from './Header';
import RandomQuote from '../components/Quotes/randomQuote'; 
import Adsense from '../components/Adsense';

function Layout({ children }) {
    return (
        <div>
            <NavBar />
            <Adsense />
            <RandomQuote />
            <main className="container">
                <div className="" style={{ minHeight: '350px' }}>
                    <div className="align-items-center">
                        {children}
                    </div>
                </div>
            </main>
            <FooterInfo />
            <footer className="border-top mt-auto">
               <Footer />
            </footer>
        </div>
    );
}

export default Layout;
