import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, CardBody, CardTitle, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import SkillList from './SkillList';
import ClassList from '../components/ClassList';


function PostList() {
  const [posts, setPosts] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const API = process.env.REACT_APP_API_URL;
  const {classId, skillId} = useParams();

  const fetchPosts = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Error fetching posts');
      }
      const data = await response.json();
      setPosts(data.results);
      setNextPage(data.next);
      setPrevPage(data.previous);
    } catch (error) {
      console.log('Error fetching posts:', error);
    }
  };
  useEffect(() => {
        let url = `${API}/`;
        if (classId && skillId) {
          url = `${API}/class/${classId}/skill/${skillId}/posts/`;
        } else if (classId) {
          url = `${API}/class/${classId}/posts/`;
        };

    fetchPosts(url);
  }, [API, classId, skillId,]);


  
  const handleNextPage = () => {
    if (nextPage) {
      fetchPosts(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (prevPage) {
      fetchPosts(prevPage);
    }
  };


  const renderPosts = () => {
    return (
      <Row>
      <ClassList />
      {classId && (
        <SkillList />
      )}
      {posts.map(post => (
        <Col key={post.id} xs={12} md={6} lg={4} className="mb-4">
          <Card className="shadow-sm">
            <CardBody>
              <Row>
                <Col xs={4} className="d-flex align-items-center">
                  {post.skill && post.skill.image && (
                    <img 
                      src={post.skill.image} 
                      alt={post.skill.name} 
                      style={{ width: '100px', height: '100px', objectFit: 'contain', transform: 'rotate(-90deg)'}} 
                      className="rounded"
                    />
                  )}
                </Col>
                <Col xs={8}>
                  <CardTitle className="h5 font-weight-bold">{post.title}</CardTitle>
                  <a href={`/post/${post.id}`} className="text-primary text-decoration-none">Read More</a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
    );
  };

  return (
    <Container>
      {renderPosts()}
      <div className="d-flex justify-content-between my-4">
        {prevPage && <Button onClick={handlePrevPage}>Previous</Button>}
        {nextPage && <Button onClick={handleNextPage}>Next</Button>}
      </div>
    </Container>
  );
}

export default PostList;