import React, { useEffect, useState } from 'react';
import { Card, Row, Container } from 'reactstrap';

function ClassList() {
    const [classes, setClasses] = useState([]);
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await fetch(`${API}/class/`);
                if (!response.ok) {
                    throw new Error('Error fetching classes');
                }
                const data = await response.json();
                setClasses(data);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        fetchClasses();
    }, [API]);

    const renderClasses = () => {
        return (
            <Row className="m-3 justify-content-center text-center">
                {classes.map(classe => (
                    <Card key={classe.id} xs={12} md={6} lg={4} className="my-1 shadow-sm col-md-4">
                        <a href={`/class/${classe.id}/posts`}>
                            <img                     
                                src={classe.image} 
                                style={{ width: '190px', height: '150px', objectFit: 'contain'}}
                                className="rounded"
                                variant="top"
                                alt={classe.name}
                            />
                        </a>
                        {/* <CardBody>
                            <CardTitle className="h5 font-weight-bold">{classe.name}</CardTitle>
                            <a href={`/class/${classe.id}/posts`} className="text-primary text-decoration-none">Read More</a>
                        </CardBody> */}
                    </Card>
                ))}
            </Row>
        );
    };

    return (
        <Container>
            {renderClasses()}
        </Container>
    );
}

export default ClassList;
