import { useEffect } from 'react';

const Adsense = () => {
    const API = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      (adsbygoogle = window.adsbygoogle || []).push({
        google_ad_client: "ca-pub-8995718407607471",
        enable_page_level_ads: true
        ${API === 'http://localhost:8000' ? 'data-adbreak-test: "on",' : ''}

      });
    `;

    document.head.appendChild(script);
    document.head.appendChild(inlineScript);

    return () => {
      // Clean up the script when the component unmounts (optional)
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, [API]);

  return null; // This component doesn't need to render anything
};

export default Adsense;    